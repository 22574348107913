<template>
  <div>
    <v-app-bar dense fixed app color="blue darken-4" dark>
      <v-btn to="/menu_data" icon>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-toolbar-title>โพยส่งออก ( ใบพิมพ์ )</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>mdi-file-find</v-icon>
      </v-btn>
    </v-app-bar>
    <v-breadcrumbs :items="bc_items" class="pa-2" dense>
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
    <div class=" mt-5" style="width:800; margin: auto;" id="printSection">
      <v-toolbar
        dense
        style="color:darkslateblue; background-color:antiquewhite;font-weight: bold;"
      >
        <div style="text-align: left;">
          {{ getAbbrFullMonthPeriodThai(PeriodDT) }}
        </div>

        <v-spacer></v-spacer>
        <div style="text-align: right;">{{ CalledMe }}</div>
      </v-toolbar>

      <v-simple-table
        v-for="item in pages_data"
        :key="item.BetTypeDetailID"
        style="border-bottom: 1px solid black;border-collapse: collapse !important; padding-top: 5px;"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th
                colspan="10"
                style="text-align: center;font-weight: bold !important; font-size: 1.6em !important;"
              >
                {{ getBetTypeText(item.BetTypeDetailID) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="index in getRowCount(item.BetTypeDetailID)" :key="index">
              <td
                style="font-size: 1.5em !important;width: 60px;border-left: 1px solid;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getNumber(item.BetTypeDetailID, index, 1) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 100px;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getPrice(item.BetTypeDetailID, index, 1) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 60px;border-left: 1px solid;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getNumber(item.BetTypeDetailID, index, 2) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 100px;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getPrice(item.BetTypeDetailID, index, 2) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 60px;border-left: 1px solid;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getNumber(item.BetTypeDetailID, index, 3) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 100px;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getPrice(item.BetTypeDetailID, index, 3) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 60px;border-left: 1px solid;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getNumber(item.BetTypeDetailID, index, 4) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 100px;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getPrice(item.BetTypeDetailID, index, 4) }}
              </td>
              <td
                style="font-size: 1.5em !important;width: 60px;border-left: 1px solid;border-top: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getNumber(item.BetTypeDetailID, index, 5) }}
              </td>
              <td
                style="font-size: 1.5em !important; width: 100px;border-top: 1px solid;border-right: 1px solid;border-color: black;font-weight: bold;"
              >
                {{ getPrice(item.BetTypeDetailID, index, 5) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <div style="text-align: right; margin-top: 3px; margin-right: 5px;">
        {{ this.BookieName }}
        </div>



    </div>

    <div class="text-center mt-5">
      <v-btn color="indigo" rounded dark @click="print()">
        <v-icon class="mr-2">mdi-printer-outline</v-icon>
        พิมพ์
      </v-btn>
    </div>
  </div>
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

export default {
  name: "Export_Page_Image",
  mixins: [globalFunctionMixin],
  components: {},

  mounted() {
    this.ckLogin();

    this.bc_items[0].text = "โพยตีออก";
    this.bc_items[0].to = { path: "export_page" };

    this.bc_items[1].text =
      this.$route.query.bookie_id +
      " ( " +
      this.$store.getters["bookie/BookiesDetail"]["Name"];
    +" )";

    this.bc_items[1].to = {
      path: "export_page_list",
      query: {
        bookie_id: this.$route.query.bookie_id,
      },
    };

    this.bc_items[2].text = this.$route.query.page_number;
  },
  created() {
    //โหลด ตาราง
    this.BookieID = this.$route.query.bookie_id;
    this.PageNumber = this.$route.query.page_number;
    this.PeriodDT = sessionStorage.getItem("period");
    this.fetch_bookie_table();
    this.fetch_table();
  },
  computed: {
    //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    pages_data() {
      return this.$store.getters["export_page/PageData"];
    },
  },
  methods: {
    async fetch_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        PageNumber: this.PageNumber,
      };
      await this.$store.dispatch("export_page/get_page_data", credentials).then(
        (response) => {
          //   console.log(this.$store.getters["agent/AgentDetail"][0].Nickname);
          //   this.bc_items[1].text =
          //     this.$route.query.bookie_id + " ( " + response[0].Name + " )";
          //   this.BookieName = response[0].Name;
          //   this.$store.getters["pages/Pages"];
        },
        (error) => {
          console.log(error);
        }
      );
    },
    async fetch_bookie_table() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        ListType: "get_detail",
      };
      await this.$store.dispatch("bookie/get_detail", credentials).then(
        (response) => {
          //   console.log(this.$store.getters["agent/AgentDetail"][0].Nickname);
          this.bc_items[1].text =
            this.$route.query.bookie_id + " ( " + response[0].Name + " )";
          this.BookieName = response[0].Name;
          this.CalledMe = response[0].called_me;
          //   this.$store.getters["pages/Pages"];
        },
        (error) => {
          console.log(error);
        }
      );
    },
    getRowCount(BetTypeDetailID) {
      for (
        let i = 0;
        i < this.$store.getters["export_page/PageData"].length;
        i++
      ) {
        if (
          this.$store.getters["export_page/PageData"][i]["BetTypeDetailID"] ==
          BetTypeDetailID
        ) {
          let PageData = this.$store.getters["export_page/PageData"][i];
          var length = PageData["Number"].length;
          return Math.floor((length - 1) / 5) + 1;
        }
      }
      return 0;
    },
    getNumber(BetTypeDetailID, Row, Column) {
      let PageDatas = this.$store.getters["export_page/PageData"];
      for (let i = 0; i < PageDatas.length; i++) {
        if (
          this.$store.getters["export_page/PageData"][i]["BetTypeDetailID"] ==
          BetTypeDetailID
        ) {
          let Number = PageDatas[i]["Number"][(Row - 1) * 5 + (Column - 1)];
          if (Number == null) return "";
          return Number;
        }
      }
      return "";
    },
    getPrice(BetTypeDetailID, Row, Column) {
      let PageDatas = this.$store.getters["export_page/PageData"];
      for (let i = 0; i < PageDatas.length; i++) {
        if (
          this.$store.getters["export_page/PageData"][i]["BetTypeDetailID"] ==
          BetTypeDetailID
        ) {
          let Price = PageDatas[i]["Price"][(Row - 1) * 5 + (Column - 1)];
          if (Price == null) return "";
          return " = " + Price;
        }
      }
      return "";
    },
    getBetTypeText(betType) {
      const statusText = [
        "3 บน",
        "3 โต๊ด",
        "2 บน",
        "2 ล่าง",
        "คู่โต๊ด",
        "",
        "พวง",
        "",
        "จม บ.น.",
        "จม บ.ก.",
        "จม บ.ท.",
        "จม. ล.น.",
        "จม. ล.ท.",
        "ลอยบน",
        "ลอยล่าง",
        "คู่หน้า",
        "คู่ถ่าง",
      ];
      return statusText[parseInt(betType - 1)];
    },
    print() {
      this.printing = true;
      console.log(this.printing);
      const options = {
        name: "_blank",
        specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
        //   styles: [
        //     'css/print.css',
        //   ]
      };
      this.$nextTick(() => {
        this.$htmlToPaper("printSection", options, () => {
          this.printing = false;
          this.do_print();
        });
      });
    },

    async do_print() {
      let credentials = {
        PeriodDT: sessionStorage.getItem("period"),
        BookieID: this.BookieID,
        PageNumber: this.PageNumber,
      };
      await this.$store.dispatch("export_page/print_page", credentials).then(
        (response) => {

        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  data: () => ({
    BookieID: null,
    PageNumber: null,
    BookieName: null,
    CalledMe: null,
    PeriodDT: "",
    printing: false,
    bc_items: [
      {
        text: "a1 ถิง",
        disabled: false,
      },
      {
        text: "Link 2",
        disabled: false,
      },
      {
        text: "Link 2",
        disabled: false,
      },
    ],
  }),
};
</script>

<style>
::v-deep table {
  border-collapse: collapse !important;
}
</style>
